import { useState, useEffect } from "react";
import "./App.css";
import {
  MantineProvider,
  Stack,
  Group,
  Button,
  Card,
  Transition,
  Text,
  Title,
  Grid,
  CloseButton,
  SegmentedControl,
  Tabs,
  Divider,
  Box,
  Image,
  Switch,
  rem,
  Avatar,
  Tooltip,
  Badge,
  ScrollArea,
  GridCol,
} from "@mantine/core";
import { useToggle } from "@mantine/hooks";
import { useFullscreen } from "@mantine/hooks";
import Login from "./components/Login";
import Logout from "./components/Logout";
import SearchPatient from "./components/SearchPatient";
import SearchMedicaments from "./components/SearchMedicaments";
import SearchBiologies from "./components/SearchBiologie";
import SearchAntecedents from "./components/SearchAntecedents";
import SearchRadiologies from "./components/SearchRadiologie";
import Calendar from "./components/Calendar";
import { useAuth } from "./hooks/useAuth";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import axios from "axios";

import {
  IconShieldCheckered,
  IconDeviceDesktopUp,
  IconDeviceDesktopDown,
  IconMail,
  IconCalendar,
  IconPlus,
  IconCheck,
  IconEdit,
  IconX,
  IconRadioactiveFilled,
  IconDropletFilled,
  IconPillFilled,
  IconShieldHalfFilled,
  IconShieldX,
  IconSwitchVertical,
  IconBandage,
  IconStethoscope,
  IconStethoscopeOff,
  IconHome,
  IconMessage,
  IconMessageChatbotFilled,
  IconMessageFilled,
  IconCalendarFilled,
  IconMailFilled,
  IconHomeFilled,
} from "@tabler/icons-react";
import { useStore } from "./Store";
import { useDisclosure } from "@mantine/hooks";
import { AntecedentsType } from "./components/FloatingAntecedentsType";
import { ObservationForm } from "./components/Observation";
import { HistoriqueTimeline } from "./components/Historique";
import { handleAntecedentClickInternal } from "./components/AddAntecedents";
import { IsearchAntecedents } from "./components/AutoSearchAntecedents";
import { ShowExamensComplementaires } from "./components/ExamensComplementaires";
import { ShowPropositionsTherapeutiques } from "./components/PropositionsTherapeutiques";
import { ShowMedicament } from "./components/ShowMedicamentDetail";
import { RecordQuestion } from "./components/AudioChat";
import { RenewtttFond } from "./components/AddDocumentOrdonnance.js";
import { Mailbox } from "./components/mail";

import {
  SaveObservation,
  resetObservationForm,
} from "./components/Observation";
// import { PrescriptionLibreForm } from "./components/PrescriptionLibre";
import { Editor } from "./components/EssaiLexical";
import { Chat } from "./components/iAvicenneChat";
import { StructureConsultData } from "./components/StructureClinique";
import { Notifications, notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import { ShowHypotheses } from "./components/HypothesesDiag";
import GenerateOrdonnance from "./components/printOrdonnance";
import GenerateOrdonnanceBiologie from "./components/printBiologie";
import GenerateOrdonnanceRadiologie from "./components/printRadiologie.js";
import SearchDispositifMedical from "./components/SearchDispositifMedical.js";
import { DownloadFile } from "./components/DownloadFile";
import GenerateOrdonnanceDispositifMedical from "./components/printDispositifMedical.js";
import { CourrierMedical } from "./components/GenerateCourrier.js";
import { GenerateOrdonnanceLibre } from "./components/printPrescLibre.js";
import { set } from "date-fns";
import { SearchPatientfromCV } from "./components/SearchPatientFromCV";
import { PatientForm } from "./components/UpdatePatient";

const client = axios.create({ baseURL: "https://www.iavicenne.fr/" });

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

function App() {
  /* particlesJS.load(@dom-id, @path-json, @callback (optional)); */
  // particlesJS.load('particles-js', 'assets/particles.json', function() {
  //   console.log('callback - particles-js config loaded');
  // });

  const { toggle, fullscreen } = useFullscreen();
  const [antecedentsOpened, { open: openDrawerAnt, close: closeDrawerAnt }] =
    useDisclosure(false);
  const [observationOpened, { open: openDrawerObs, close: closeDrawerObs }] =
    useDisclosure(false);

  const [chat, toggleChat] = useToggle(["showAudioChat", "showChat"]);
  const [values, setFormValues] = useState({});
  const { currentUser, setCurrentUser, error, logout } = useAuth();
  // Utilisation de la déstructuration pour simplifier les imports
  const {
    // currentUser,
    currentSessionInfo,
    setCurrentSessionInfo,
    selectedPatientInfo,
    setSelectedPatientInfo,
    agendaOpened,
    setAgendaOpened,
    showPatientCard,
    setShowPatientCard,
    loading,
    setLoading,
    showEncouterObjects,
    setShowEncouterObjects,
    ordonnanceData,
    setOrdonnanceData,
    biologieData,
    setBiologieData,
    radiologieData,
    setRadiologieData,
    courrierData,
    dispositifMedicalData,
    setDispositifMedicalData,
    activeEncounter,
    setActiveEncounter,
    antecedentSearchType,
    setShowAntecedentsSearch,
    observationData,
    setObservationData,
    setHistoriqueData,
    selectedMedicamentDetail,
    setSelectedMedicamentDetail,
    active,
    activeTab,
    data,
    setActive,
    setActiveTab,
    middleActiveTab,
    setMiddleActiveTab,
    middleActive,
    setMiddleActive,
    getMiddleData,
    shouldSaveCourrier,
    setShouldSaveCourrier,
    ordonnanceLibreData,
    setOrdonnanceLibreData,
    setEditorState,
    mailboxOpened,
    setMailboxOpened,
  } = useStore();

  // Nouvelle Consultation
  const CreateEncounter = async () => {
    if (activeEncounter) {
      console.log("Consultation active déjà en cours");
      // console.log(activeEncounter);
      // console.log(currentSessionInfo);
      return;
    }

    try {
      // Si pas de consultation active en cours, créer une nouvelle consultation active
      setShowEncouterObjects(true);
      setMiddleActive("Observation Médicale");
      setMiddleActiveTab("Observation Médicale");
      setActive("medicaments");
      setActiveTab("medicaments");
      const encounterData = {
        practitioner: currentUser.id,
        subject: selectedPatientInfo.id,
        status: "active",
      };

      const response = await client.post("api/encounters/", encounterData, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.status === 201) {
        const currentSessionData = {
          practitioner: currentUser.id,
          subject: selectedPatientInfo.id,
          encounter: response.data.id,
        };
        setCurrentSessionInfo(currentSessionData);

        try {
          const response_2 = await client.get(
            `api/current-session/?practitioner=${currentUser.id}`
          );
          if (response_2.status === 200) {
            const response_3 = await client.put(
              `api/current-session/${response_2.data[0].id}/`,
              currentSessionData,
              {
                headers: { "Content-Type": "application/json" },
              }
            );
            if (response_3.status === 200 || response_2.status === 204) {
              // setShowEncouterObjects(true);
              setActiveEncounter(true);
            } else {
              console.error("Failed to update currentSession:", response_2);
            }
          } else {
            const response_3 = await client.post(
              "api/current-session/",
              currentSessionData,
              {
                headers: { "Content-Type": "application/json" },
              }
            );
          }
        } catch (error) {
          console.error("Failed to update currentSession:", error);
        }
      } else {
        console.error("Failed to create encounter:", response);
      }
    } catch (error) {
      console.error("Failed to create encounter:", error);
    }
  };

  const handleFormValuesChange = (values) => {
    setFormValues(values);
  };

  const SaveEncounter = async () => {
    const EncounterData = {
      observationMedicale: observationData || {},
      ordonnanceMedicaments: ordonnanceData || [],
      ordonnanceBiologie: biologieData || [],
      ordonnanceRadiologie: radiologieData || [],
      ordonnanceDispositifMedical: dispositifMedicalData || [],
      courrierMedical: courrierData || [],
      ordonnanceLibre: ordonnanceLibreData || [],
    };
    // console.log("EncounterData au début de SaveEncounter:", EncounterData);
    // console.log("currentSessionInfo:", currentSessionInfo);
    async function fetchEncounters() {
      try {
        const response = await client.get(
          `api/encounters/?practitioner=${currentSessionInfo.practitioner}&subject=${currentSessionInfo.subject}&status=archived`
        );
        setHistoriqueData(response.data);
      } catch (error) {
        console.error("Failed to fetch encounters:", error);
      }
    }

    const areAllContentValuesNull = (data) => {
      if (!data) return true;
      if (Array.isArray(data)) {
        return data.every(
          (item) => !item || item.content === null || item.content === ""
        );
      }
      if (typeof data === "object") {
        return Object.values(data).every((value) =>
          areAllContentValuesNull(value)
        );
      }
      return data === null || data === "";
    };

    // Vérifier si toutes les propriétés de EncounterData sont vides ou null
    if (
      areAllContentValuesNull(
        EncounterData.observationMedicale.observationContent
      ) &&
      areAllContentValuesNull(EncounterData.ordonnanceMedicaments) &&
      areAllContentValuesNull(EncounterData.ordonnanceBiologie) &&
      areAllContentValuesNull(EncounterData.ordonnanceRadiologie) &&
      areAllContentValuesNull(EncounterData.ordonnanceDispositifMedical) &&
      areAllContentValuesNull(EncounterData.ordonnanceLibre)
    ) {
      console.log("nothing to save");
      setShowEncouterObjects(false);
      setActiveEncounter(false);
      handleHistoriqueControlChange("Historique des Consultations");

      try {
        const response = await client.delete(
          `api/encounters/${currentSessionInfo.encounter}/`
        );
      } catch (error) {
        console.error(
          "Erreur lors de la suppression de la consultation active:",
          error
        );
      }
    } else {
      try {
        console.log(currentSessionInfo);
        const response = await client.patch(
          `api/encounters/${currentSessionInfo.encounter}/`,
          {
            encounterData: EncounterData,
            status: "archived",
          }
        );
        if (response.status === 200) {
          console.log("Encounter saved");
          setMiddleActive("Historique des Consultations");
          setMiddleActiveTab("Historique des Consultations");
          setShowEncouterObjects(false);
          setOrdonnanceData([]);
          setBiologieData([]);
          setRadiologieData([]);
          setDispositifMedicalData([]);
          setOrdonnanceLibreData([]);
          setEditorState([]);
          setActiveEncounter(false);
          setShouldSaveCourrier(true);

          // setRadiologieData([]);
          // setPresLibreData([]);
          // const response = await client.patch(
          //   `api/current-session/${currentSessionInfo.id}/`,
          //   {
          //     encounter: null,
          //   }
          // );

          const success = await SaveObservation(
            setObservationData,
            observationData
          );
          if (success) {
            resetObservationForm();
          }
          fetchEncounters();
        }
      } catch (error) {
        console.error(
          "Erreur lors de la sauvegarde de la consultation:",
          error
        );
      }
    }
  };

  const deleteMedicament = async (id) => {
    // Mettre à jour immédiatement l'état pour supprimer l'élément du DOM
    const prevData = ordonnanceData.filter((item) => item.id !== id);
    setOrdonnanceData(prevData);

    try {
      const response = await client.delete(`api/medication-requests/${id}/`);
      if (response.status === 204) {
        console.log("médicament supprimé");
        setSelectedMedicamentDetail(null);
      } else console.log(response);
    } catch (error) {
      console.error("Erreur lors de la suppression du médicament:", error);
    }
  };

  const deleteBiologie = async (id) => {
    // Mettre à jour immédiatement l'état pour supprimer l'élément du DOM
    const prevData = biologieData.filter((item) => item.id !== id);
    setBiologieData(prevData);

    try {
      const response = await client.delete(`api/service-request/${id}/`);
      if (response.status === 204) {
        console.log("biologie supprimée");
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du médicament:", error);
    }
  };

  const deleteRadiologie = async (id) => {
    // Mettre à jour immédiatement l'état pour supprimer l'élément du DOM
    const prevData = radiologieData.filter((item) => item.id !== id);
    setRadiologieData(prevData);

    try {
      const response = await client.delete(`api/service-request/${id}/`);
      if (response.status === 204) {
        console.log("radiologie supprimée");
      }
    } catch (error) {
      console.error(
        "Erreur lors de la suppression de l'examen de radiologie:",
        error
      );
    }
  };

  const deleteDispositifMedical = async (id) => {
    // Mettre à jour immédiatement l'état pour supprimer l'élément du DOM
    const prevData = dispositifMedicalData.filter((item) => item.id !== id);
    setDispositifMedicalData(prevData);

    try {
      const response = await client.delete(`api/service-request/${id}/`);
      if (response.status === 204) {
        console.log("DM supprimée");
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du DM:", error);
    }
  };

  const handleAntecedentClick = (value) => {
    handleAntecedentClickInternal(
      value,
      antecedentSearchType,
      selectedPatientInfo,
      setSelectedPatientInfo,
      setShowAntecedentsSearch
    );
  };
  // Supprimer un antecedents
  const deleteAntecedent = async (type, id) => {
    try {
      let updatedAntecedents, updatedFields, antecedentKey;

      switch (type) {
        case "medical":
          updatedAntecedents = selectedPatientInfo.antecedentsMedicaux.filter(
            (antecedent) => {
              antecedentKey = Object.keys(antecedent)[0];
              return antecedent[antecedentKey].id !== id;
            }
          );
          updatedFields = { antecedentsMedicaux: updatedAntecedents };

          break;

        case "chirurgical":
          updatedAntecedents =
            selectedPatientInfo.antecedentsChirurgicaux.filter((antecedent) => {
              antecedentKey = Object.keys(antecedent)[0];
              return antecedent[antecedentKey].id !== id;
            });
          updatedFields = { antecedentsChirurgicaux: updatedAntecedents };
          break;

        case "familial":
          updatedAntecedents = selectedPatientInfo.antecedentsFamiliaux.filter(
            (antecedent) => {
              antecedentKey = Object.keys(antecedent)[0];
              return antecedent[antecedentKey].id !== id;
            }
          );
          updatedFields = { antecedentsFamiliaux: updatedAntecedents };
          break;

        case "allergies":
          updatedAntecedents = selectedPatientInfo.allergies.filter(
            (antecedent) => {
              antecedentKey = Object.keys(antecedent)[0];
              return antecedent[antecedentKey].id !== id;
            }
          );
          updatedFields = { allergies: updatedAntecedents };
          break;

        // case "traitements":
        //   updatedMedicationStatements = selectedPatientInfo.MedicationStatement.filter(
        //     (medicament) => medicament.id !== id
        //   );
        //   updatedFields = { MedicationStatement: updatedMedicationStatements };
        //   setSelectedPatientInfo((prevInfo) => ({
        //     ...prevInfo,
        //     MedicationStatement: updatedMedicationStatements,
        //   }));
        //   break;

        default:
          throw new Error(`Unknown antecedent type: ${type}`);
      }

      // Requête PATCH pour mettre à jour le modèle du patient
      const response = await client.patch(
        `api/patients/${selectedPatientInfo.id}/`,
        updatedFields
      );

      // Vérification de la réponse du serveur
      if (response.status >= 200 && response.status < 300) {
        console.log("Antécédent supprimé avec succès");
        setSelectedPatientInfo(response.data);
      } else {
        console.log("La suppression de l'antécédent n'a pas pu être effectuée");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deletetttFond = async (medoc_id) => {
    try {
      const prevData = selectedPatientInfo.MedicationStatement.filter(
        (item) => item.medoc_id !== medoc_id
      );
      const updatedFields = { MedicationStatement: prevData };

      // Requête PATCH pour mettre à jour le modèle du patient
      const response = await client.patch(
        `api/patients/${selectedPatientInfo.id}/`,
        updatedFields
      );
      // Vérification de la réponse du serveur
      if (response.status >= 200 && response.status < 300) {
        console.log("ttt de fond supprimé avec succès");
        setSelectedPatientInfo(response.data);
      } else {
        console.log("La suppression de l'antécédent n'a pas pu être effectuée");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrescriptionControlChange = (value) => {
    setActive(value);
    setActiveTab(value);
  };

  // historique tab

  const middleData = getMiddleData();

  const handleHistoriqueControlChange = (value) => {
    setMiddleActive(value);
    setMiddleActiveTab(value);
  };

  const handleMedicamentControlChange = (item) => {
    setMiddleActive("Medicament");
    setMiddleActiveTab("Medicament");
    setSelectedMedicamentDetail(item);
  };

  const handleDispositifMedicalControlChange = (item) => {
    setActive("DispositifsMedicaux");
    setActiveTab("DispositifsMedicaux");
  };

  const handleAntecedentsControlChange = () => {
    setMiddleActive("Modifier les Antecedents");
    setMiddleActiveTab("Modifier les Antecedents");
  };

  const handlePatientFormControlChange = () => {
    setShowPatientCard(true)
    setMiddleActive("Données Patient");
    setMiddleActiveTab("Données Patient");
    setAgendaOpened(false);
    setMailboxOpened(false);
  };
  const handledocumentsControlChange = () => {
    setMiddleActive("Documents");
    setMiddleActiveTab("Documents");
  };

  // Fonction pour mettre à jour tttAld
  const handleBiologieSwitchChange = (serviceId, index, checked) => {
    const updatedData = biologieData.map((item) => {
      if (item.ServiceId === serviceId) {
        const updatedServiceContent = [...item.ServiceContent];
        updatedServiceContent[index].tttAld = checked; // Mettre à jour tttAld selon l'état du switch
        return { ...item, ServiceContent: updatedServiceContent };
      }
      return item;
    });
    setBiologieData(updatedData); // Mettre à jour l'état
  };

  const handleRadiologieSwitchChange = (serviceId, index, checked) => {
    const updatedData = radiologieData.map((item) => {
      if (item.ServiceId === serviceId) {
        const updatedServiceContent = [...item.ServiceContent];
        updatedServiceContent[index].tttAld = checked; // Mettre à jour tttAld selon l'état du switch
        return { ...item, ServiceContent: updatedServiceContent };
      }
      return item;
    });
    setRadiologieData(updatedData); // Mettre à jour l'état
    console.log(radiologieData);
  };

  const handleDispositifMedicalSwitchChange = (serviceId, index, checked) => {
    const updatedData = dispositifMedicalData.map((item) => {
      if (item.ServiceId === serviceId) {
        const updatedServiceContent = [...item.ServiceContent];
        updatedServiceContent[index].tttAld = checked; // Mettre à jour tttAld selon l'état du switch
        return { ...item, ServiceContent: updatedServiceContent };
      }
      return item;
    });
    setDispositifMedicalData(updatedData); // Mettre à jour l'état
    console.log(dispositifMedicalData);
  };

  const handleHomeClick = () => {
    setSelectedPatientInfo("");
    setAgendaOpened(false);
    setMailboxOpened(false);
    setShowPatientCard(false);
  };

  const handleChatClick = () => {
    setShowPatientCard(true)
    setMiddleActive("iAvicenne Chat");
    setMiddleActiveTab("iAvicenne Chat");
    setAgendaOpened(false);
    setMailboxOpened(false);
  };

  return (
    <MantineProvider
      theme={{
        fontSizes: {
          xs: rem(10),
        },
        lineHeights: {
          xs: "1.4",
        },
      }}
    >
      <Notifications
        position="bottom-right"
        zIndex={1000}
        transitionDuration={500}
        containerWidth={370}
        notificationMaxHeight={170}
        limit={5}
      />
      <div className="App">
        <Image
          className="ArrierePlan"
          radius="md"
          src={null}
          fit="contain"
          fallbackSrc="https://www.iavicenne.fr/static/img/arriere_plan.png"
        />
        <Image
          className="ArrierePlanBrain"
          radius="md"
          src={null}
          fit="contain"
          fallbackSrc="https://www.iavicenne.fr/static/img/brain_ai_3.png"
        />

        {!loading && currentUser ? (
          <div>
            <Button
              className="fullscreenButton"
              variant="filled"
              onClick={toggle}
              color={fullscreen ? "#94ADA7" : "#94ADA7"}
            >
              {fullscreen ? <IconDeviceDesktopDown /> : <IconDeviceDesktopUp />}
            </Button>

            <Stack>
              <Group className="navBar" justify="space-between">
                <Group>
                  {selectedPatientInfo && currentSessionInfo.subject && (
                    <Button
                      variant="filled"
                      radius="md"
                      padding="xs"
                      color="#94ADA7"
                      withBorder={true}
                      onClick={handlePatientFormControlChange}
                    >
                      {/* <ThemeIcon color="#94ada7" size={30} radius="lg">
                      <IconUserCheck size="20px" />
                                         </ThemeIcon> */}
                      <Avatar radius="xl" size="sm" color="White" />
                      <Text size="sm">
                        <strong>
                          &nbsp;&nbsp;
                          {selectedPatientInfo.NomNaissance}{" "}
                          {selectedPatientInfo.UsagePrenom}
                        </strong>
                      </Text>
                    </Button>
                  )}

                  <StructureConsultData />
                  <IsearchAntecedents />
                  <SearchPatient />
                 < SearchPatientfromCV/>
                 {/* <Button onClick={handlePatientFormControlChange}/> */}

                  {selectedPatientInfo &&
                    currentSessionInfo.subject &&
                    (showEncouterObjects ? (
                      <>
                        <Button
                          className="CloseObservationButton"
                          variant="filled"
                          color="#94ADA7"
                          radius="md"
                          size="sm"
                          onClick={SaveEncounter}
                        >
                          <Group gap="xs">
                            <IconStethoscopeOff size="18" />
                           <IconCheck/>
                          </Group>
                        </Button>
                      </>
                    ) : (
                      <Button
                        className="NewObservationButton"
                        variant="filled"
                        color="#94ADA7"
                        radius="md"
                        size="sm"
                        onClick={CreateEncounter}
                      >
                        <Group gap="xs">
                          <IconStethoscope size="18" />
                         <IconPlus/>
                        </Group>
                      </Button>
                    ))}
                </Group>
                <Group>
                <Button
                    className="MessageButton"
                    variant="filled"
                    radius="md"
                    color="#94ADA7"
                    onClick={handleChatClick}
                  >
                    <IconMessageFilled /><Text size="sm">&nbsp;iA Chat</Text>
                  </Button>
                 
                  <Button
                    onClick={() => {
                      setAgendaOpened(true);
                      setMailboxOpened(false);
                      setShowPatientCard(false);
                    }}
                    className="AgendaButton"
                    variant="filled"
                    radius="md"
                    color="#94ADA7"
                  >
                    <IconCalendarFilled /><Text size="sm">&nbsp;Agenda</Text>
                  </Button>
                  <Button
                    className="MessageButton"
                    variant="filled"
                    radius="md"
                    color="#94ADA7"
                    onClick={() => {
                      setMailboxOpened(true);
                      setAgendaOpened(false);
                      setShowPatientCard(false);
                    }}
                  >
                    <IconMailFilled /><Text size="sm">&nbsp;Mssanté</Text>
                  </Button>
                  <Button
                    className="MessageButton"
                    variant="filled"
                    radius="md"
                    color="#94ADA7"
                    onClick={handleHomeClick}
                  >
                    <IconHomeFilled />
                  </Button>

                  <Logout />
                </Group>
              </Group>

              <>
                {showPatientCard && selectedPatientInfo && (
                  <>
                    <Grid>
                      <Grid.Col span="2.2" className="antecedentsGridCol">
                        <Divider
                          my="xs"
                          label={
                            <>
                              <Box ml={5}>Antécédents&nbsp;&nbsp;</Box>
                              <IconEdit
                                size={16}
                                onClick={handleAntecedentsControlChange}
                                cursor={"pointer"}
                              />
                            </>
                          }
                          labelPosition="center"
                        />
                        <ScrollArea
                          h={"92vh"}
                          w={"100%"}
                          offsetScrollbars
                          scrollHideDelay={100}
                          scrollbar="never"
                          style={{
                            paddingLeft: 20,
                            margin: "none",
                            paddingBottom: 50,
                            minWidth: "100%",
                          }}
                        >
                          <Stack justify="flex-start">
                            {selectedPatientInfo.antecedentsMedicaux &&
                              selectedPatientInfo.antecedentsMedicaux.length >
                                0 && (
                                <Card
                                  shadow="sm"
                                  padding="md"
                                  radius="lg"
                                  withBorder={true}
                                  style={{
                                    zIndex: 1000,
                                    maxWidth: "100%",
                                  }}
                                >
                                  <Title order={5}>Antécédents Médicaux</Title>

                                  <Stack
                                    className="antMedList"
                                    align="flex-start"
                                    justify="flex-start"
                                    gap="xs"
                                  >
                                    {selectedPatientInfo.antecedentsMedicaux.map(
                                      (antecedent, index) => {
                                        const key = Object.keys(antecedent)[0]; // Obtenir la première clé de l'objet antecedent
                                        return (
                                          <Button
                                            className="antMedButton"
                                            justify="space-between"
                                            size="xs"
                                            variant="outline"
                                            radius="lg"
                                            color="#94ADA7"
                                            onClick={
                                              handleAntecedentsControlChange
                                            }
                                            rightSection={
                                              middleActive ===
                                                "Modifier les Antecedents" && (
                                                <CloseButton
                                                  onClick={() => {
                                                    deleteAntecedent(
                                                      "medical",
                                                      antecedent[key].id
                                                    );
                                                  }}
                                                  icon={
                                                    <IconX
                                                      size={18}
                                                      stroke={1.5}
                                                      color="red"
                                                    />
                                                  }
                                                />
                                              )
                                            }
                                          >
                                            <Text
                                              fw={700}
                                              tt="capitalize"
                                              size="xs"
                                              c="black"
                                              truncate="end"
                                              lineClamp={1}
                                            >
                                              {antecedent[key].Code && (
                                                <IconShieldHalfFilled
                                                  color="#94ADA7"
                                                  size={16}
                                                  style={{
                                                    verticalAlign: "middle",
                                                  }}
                                                />
                                              )}
                                              {!antecedent[key].Code && (
                                                <IconShieldX
                                                  color="#94ADA7"
                                                  size={16}
                                                  style={{
                                                    verticalAlign: "middle",
                                                  }}
                                                />
                                              )}
                                              &nbsp;
                                              {antecedent[key].Title.replace(
                                                /-/g,
                                                ""
                                              )}{" "}
                                            </Text>
                                          </Button>
                                        );
                                      }
                                    )}
                                  </Stack>
                                </Card>
                              )}
                            {selectedPatientInfo.antecedentsChirurgicaux &&
                              selectedPatientInfo.antecedentsChirurgicaux
                                .length > 0 && (
                                <Card
                                  shadow="sm"
                                  padding="md"
                                  radius="lg"
                                  withBorder={true}
                                  style={{
                                    zIndex: 1000,
                                    maxWidth: "100%",
                                  }}
                                >
                                  <Title
                                    order={5}
                                    style={{ stylemarginBottom: "20" }}
                                  >
                                    Antécédents Chirurgicaux
                                  </Title>
                                  <Stack
                                    className="antMedList"
                                    align="flex-start"
                                    justify="flex-start"
                                    gap="xs"
                                  >
                                    {selectedPatientInfo.antecedentsChirurgicaux.map(
                                      (antecedent, index) => {
                                        const key = Object.keys(antecedent)[0]; // Obtenir la première clé de l'objet antecedent
                                        return (
                                          <Button
                                            className="antChirButton"
                                            onClick={
                                              handleAntecedentsControlChange
                                            }
                                            justify="space-between"
                                            size="xs"
                                            variant="outline"
                                            radius="lg"
                                            color="#94ADA7"
                                            // fullWidth
                                            // leftSection={
                                            //   <IconShieldCheckered size={14} />
                                            // }
                                            rightSection={
                                              middleActive ===
                                                "Modifier les Antecedents" && (
                                                <CloseButton
                                                  onClick={() => {
                                                    deleteAntecedent(
                                                      "chirurgical",
                                                      antecedent[key].id
                                                    );
                                                  }}
                                                  icon={
                                                    <IconX
                                                      size={18}
                                                      stroke={1.5}
                                                      color="red"
                                                    />
                                                  }
                                                />
                                              )
                                            }
                                          >
                                            <Text
                                              fw={700}
                                              tt="capitalize"
                                              size="xs"
                                              color="black"
                                              truncate="end"
                                              lineClamp={1}
                                            >
                                              {antecedent[key].Code && (
                                                <IconShieldHalfFilled
                                                  color="#94ADA7"
                                                  size={16}
                                                  style={{
                                                    verticalAlign: "middle",
                                                  }}
                                                />
                                              )}
                                              {!antecedent[key].Code && (
                                                <IconShieldX
                                                  color="#94ADA7"
                                                  size={16}
                                                  style={{
                                                    verticalAlign: "middle",
                                                  }}
                                                />
                                              )}
                                              &nbsp;
                                              {antecedent[key].Title.replace(
                                                /-/g,
                                                ""
                                              )}{" "}
                                            </Text>
                                          </Button>
                                        );
                                      }
                                    )}
                                  </Stack>
                                </Card>
                              )}
                            {selectedPatientInfo.antecedentsFamiliaux &&
                              selectedPatientInfo.antecedentsFamiliaux.length >
                                0 && (
                                <Card
                                  shadow="sm"
                                  padding="md"
                                  radius="lg"
                                  withBorder={true}
                                  style={{
                                    zIndex: 1000,
                                    maxWidth: "100%",
                                  }}
                                >
                                  <Title
                                    order={5}
                                    style={{ stylemarginBottom: "20" }}
                                  >
                                    Antécédents Familiaux
                                  </Title>
                                  <Stack
                                    className="antMedList"
                                    align="flex-start"
                                    justify="flex-start"
                                    gap="xs"
                                  >
                                    {selectedPatientInfo.antecedentsFamiliaux.map(
                                      (antecedent, index) => {
                                        const key = Object.keys(antecedent)[0]; // Obtenir la première clé de l'objet antecedent
                                        return (
                                          <Button
                                            className="antFamButton"
                                            onClick={
                                              handleAntecedentsControlChange
                                            }
                                            justify="space-between"
                                            size="xs"
                                            variant="outline"
                                            radius="lg"
                                            color="#94ADA7"
                                            // fullWidth
                                            // leftSection={
                                            //   <IconShieldCheckered size={14} />
                                            // }
                                            rightSection={
                                              middleActive ===
                                                "Modifier les Antecedents" && (
                                                <CloseButton
                                                  onClick={() => {
                                                    deleteAntecedent(
                                                      "familial",
                                                      antecedent[key].id
                                                    );
                                                  }}
                                                  icon={
                                                    <IconX
                                                      size={18}
                                                      stroke={1.5}
                                                      color="red"
                                                    />
                                                  }
                                                />
                                              )
                                            }
                                          >
                                            <Text
                                              fw={700}
                                              tt="capitalize"
                                              size="xs"
                                              color="black"
                                              truncate="end"
                                              lineClamp={1}
                                            >
                                              {antecedent[key].Code && (
                                                <IconShieldHalfFilled
                                                  color="#94ADA7"
                                                  size={16}
                                                  style={{
                                                    verticalAlign: "middle",
                                                  }}
                                                />
                                              )}
                                              {!antecedent[key].Code && (
                                                <IconShieldX
                                                  color="#94ADA7"
                                                  size={16}
                                                  style={{
                                                    verticalAlign: "middle",
                                                  }}
                                                />
                                              )}
                                              &nbsp;
                                              {antecedent[key].Title.replace(
                                                /-/g,
                                                ""
                                              )}{" "}
                                            </Text>
                                          </Button>
                                        );
                                      }
                                    )}
                                  </Stack>
                                </Card>
                              )}
                            {selectedPatientInfo.allergies &&
                              selectedPatientInfo.allergies.length > 0 && (
                                <Card
                                  shadow="sm"
                                  padding="md"
                                  radius="lg"
                                  withBorder={true}
                                  style={{
                                    zIndex: 1000,
                                    maxWidth: "100%",
                                  }}
                                >
                                  <Title
                                    order={5}
                                    style={{ stylemarginBottom: "20" }}
                                  >
                                    Allergies
                                  </Title>
                                  <Stack
                                    className="antMedList"
                                    align="flex-start"
                                    justify="flex-start"
                                    gap="xs"
                                  >
                                    {selectedPatientInfo.allergies.map(
                                      (antecedent, index) => {
                                        const key = Object.keys(antecedent)[0]; // Obtenir la première clé de l'objet antecedent
                                        return (
                                          <Button
                                            className="antFamButton"
                                            onClick={
                                              handleAntecedentsControlChange
                                            }
                                            justify="space-between"
                                            size="xs"
                                            variant="outline"
                                            radius="lg"
                                            color="#94ADA7"
                                            // fullWidth
                                            // leftSection={
                                            //   <IconShieldCheckered size={14} />
                                            // }
                                            rightSection={
                                              middleActive ===
                                                "Modifier les Antecedents" && (
                                                <CloseButton
                                                  onClick={() => {
                                                    deleteAntecedent(
                                                      "allergies",
                                                      antecedent[key].id
                                                    );
                                                  }}
                                                  icon={
                                                    <IconX
                                                      size={18}
                                                      stroke={1.5}
                                                      color="red"
                                                    />
                                                  }
                                                />
                                              )
                                            }
                                          >
                                            <Text
                                              fw={700}
                                              tt="capitalize"
                                              size="xs"
                                              color="black"
                                              truncate="end"
                                              lineClamp={1}
                                            >
                                              {antecedent[key].Code && (
                                                <IconShieldHalfFilled
                                                  color="#94ADA7"
                                                  size={16}
                                                  style={{
                                                    verticalAlign: "middle",
                                                  }}
                                                />
                                              )}
                                              {!antecedent[key].Code && (
                                                <IconShieldX
                                                  color="#94ADA7"
                                                  size={16}
                                                  style={{
                                                    verticalAlign: "middle",
                                                  }}
                                                />
                                              )}
                                              &nbsp;
                                              {antecedent[key].Title.replace(
                                                /-/g,
                                                ""
                                              )}{" "}
                                            </Text>
                                          </Button>
                                        );
                                      }
                                    )}
                                  </Stack>
                                </Card>
                              )}
                            {selectedPatientInfo.MedicationStatement &&
                              selectedPatientInfo.MedicationStatement.length >
                                0 && (
                                <Card
                                  shadow="sm"
                                  padding="md"
                                  radius="lg"
                                  withBorder={true}
                                  style={{
                                    zIndex: 1000,
                                    maxWidth: "100%",
                                  }}
                                >
                                  <Group justify="space-between">
                                    <Title
                                      order={5}
                                      style={{ stylemarginBottom: "20" }}
                                    >
                                      Traitement de fond
                                    </Title>
                                    {showEncouterObjects && <RenewtttFond />}
                                  </Group>
                                  <Stack
                                    className="antMedList"
                                    align="flex-start"
                                    justify="flex-start"
                                    gap="xs"
                                  >
                                    {selectedPatientInfo.MedicationStatement.map(
                                      (item, index) => {
                                        return (
                                          <Button
                                            className="tttFondButton"
                                            onClick={
                                              handleAntecedentsControlChange
                                            }
                                            justify="space-between"
                                            size="xs"
                                            variant="outline"
                                            radius="lg"
                                            color="#94ADA7"
                                            // fullWidth

                                            rightSection={
                                              middleActive ===
                                                "Modifier les Antecedents" && (
                                                <CloseButton
                                                  onClick={() => {
                                                    deletetttFond(
                                                      item.medoc_id
                                                    );
                                                  }}
                                                  icon={
                                                    <IconX
                                                      size={18}
                                                      stroke={1.5}
                                                      color="red"
                                                    />
                                                  }
                                                />
                                              )
                                            }
                                          >
                                            <Text
                                              fw={700}
                                              tt="capitalize"
                                              size="xs"
                                              c="black"
                                              truncate="end"
                                              lineClamp={1}
                                            >
                                              <IconPillFilled
                                                color="#94ADA7"
                                                size={16}
                                                style={{
                                                  verticalAlign: "middle",
                                                }}
                                              />
                                              &nbsp;{item.nom_commercial}
                                            </Text>
                                          </Button>
                                        );
                                      }
                                    )}
                                  </Stack>
                                </Card>
                              )}
                          </Stack>
                        </ScrollArea>
                      </Grid.Col>

                      <Grid.Col span="5" className="historiqueGridCol">
                        <Divider
                          my="xs"
                          label={middleActiveTab}
                          labelPosition="center"
                        />

                        <SegmentedControl
                          className="HistoriqueControl"
                          withItemsBorders={false}
                          data={middleData}
                          value={middleActive}
                          onChange={handleHistoriqueControlChange}
                          color="#94ADA7"
                          radius="md"
                          size="xs"
                          transitionDuration={500}
                          transitionTimingFunction="linear"
                          fullWidth
                          style={{ marginBottom: 20 }}
                        />
                        <Tabs
                          value={middleActiveTab}
                          onChange={setMiddleActiveTab}
                          radius="lg"
                          transitionDuration={800}
                        >
                          <Tabs.Panel value="Historique des Consultations">
                            <div className="HistoriqueTimeline">
                              <HistoriqueTimeline />
                            </div>
                          </Tabs.Panel>

                          <Tabs.Panel value="Observation Médicale">
                            <Card radius="lg" shadow="lg" withBorder={true}>
                              <ObservationForm
                                onValuesChange={handleFormValuesChange}
                              />
                            </Card>
                          </Tabs.Panel>

                          <Tabs.Panel value="Courrier Médical">
                            <Card radius="lg" shadow="lg" withBorder={true}>
                              <CourrierMedical />
                            </Card>
                          </Tabs.Panel>

                          <Tabs.Panel
                            value="iAvicenne Chat"
                            className="iAvicenne-Chat"
                          >
                            <Group>
                              {chat === "showAudioChat" && <RecordQuestion />}
                              {chat === "showChat" && <Chat />}

                              <Button
                                className="toggleChatButton"
                                size="xs"
                                variant="subtle"
                                color="#94ADA7"
                                radius="lg"
                                shadow="lg"
                                onClick={() => toggleChat()}
                              >
                                <IconSwitchVertical />
                              </Button>
                            </Group>
                          </Tabs.Panel>

                          <Tabs.Panel value="Hypotheses Diagnostiques">
                            <ShowHypotheses />
                          </Tabs.Panel>

                          <Tabs.Panel value="Examens Complementaires">
                            <ShowExamensComplementaires />
                          </Tabs.Panel>

                          <Tabs.Panel value="Propositions Thérapeutiques">
                            <ShowPropositionsTherapeutiques />
                          </Tabs.Panel>
                          <Tabs.Panel value="Medicament">
                            {selectedMedicamentDetail && <ShowMedicament />}
                          </Tabs.Panel>

                          <Tabs.Panel value="Modifier les Antecedents">
                            <AntecedentsType />
                            <SearchAntecedents
                              data-autofocus
                              className="SearchAntecedents"
                              handleAntecedentClick={handleAntecedentClick}
                            />
                          </Tabs.Panel>
                          <Tabs.Panel value="Documents">
                            <DownloadFile />
                          </Tabs.Panel>
                          <Tabs.Panel value="Données Patient">
                            <PatientForm />
                          </Tabs.Panel>
                        </Tabs>
                      </Grid.Col>
                      {showEncouterObjects && (
                        <Grid.Col span="4.8" className="ordonnanceGridCol">
                          <Divider
                            my="xs"
                            label="Prescription des Ordonnances"
                            labelPosition="center"
                          />

                          <Tabs
                            value={activeTab}
                            onChange={setActiveTab}
                            radius="lg"
                            transitionDuration={800}
                          >
                            <SegmentedControl
                              className="PrescriptionControl"
                              withItemsBorders={false}
                              data={data}
                              value={active}
                              onChange={handlePrescriptionControlChange}
                              color="#94ADA7"
                              radius="md"
                              size="xs"
                              transitionDuration={500}
                              transitionTimingFunction="linear"
                              fullWidth
                            />
                            <ScrollArea
                              h={"82vh"}
                              w={"100%"}
                              offsetScrollbars
                              scrollHideDelay={100}
                              scrollbar="never"
                            >
                              <Tabs.Panel value="medicaments">
                                <Card
                                  className="OrdoMedCard"
                                  shadow="lg"
                                  radius="lg"
                                  p="md"
                                  withBorder={true}
                                >
                                  <Stack
                                    className="medicamentsList"
                                    align="stretch"
                                    justify="flex-start"
                                    gap="xs"
                                  >
                                    <Group justify="space-between">
                                      <Title size="h4">&nbsp;Médicaments</Title>{" "}
                                      <GenerateOrdonnance type="ordonnance" />
                                    </Group>
                                    <SearchMedicaments className="SearchMedicaments" />
                                    {ordonnanceData?.map?.((item, index) => (
                                      <Button
                                        className="medicamentsButton"
                                        justify="space-between"
                                        size="xs"
                                        variant="outline"
                                        radius="lg"
                                        color="#94ADA7"
                                        onClick={() => {
                                          handleMedicamentControlChange(item);
                                        }}
                                        rightSection={
                                          <CloseButton
                                            className="CloseButton"
                                            zIndex="3000"
                                            onClick={() => {
                                              deleteMedicament(item.id);
                                            }}
                                            icon={
                                              <IconX
                                                size={18}
                                                stroke={1.5}
                                                color="red"
                                              />
                                            }
                                          />
                                        }
                                        fullWidth
                                      >
                                        <Text
                                          className="TextMedicament"
                                          size="xs"
                                          color="black"
                                          truncate="end"
                                          lineClamp={1}
                                        >
                                          <IconPillFilled
                                            className="MedicamentIcon"
                                            color="#94ADA7"
                                            style={{ verticalAlign: "middle" }}
                                          />
                                          &nbsp;&nbsp;
                                          <strong>
                                            {item.nom_commercial}
                                          </strong>{" "}
                                          {" ("}
                                          {item.dci}
                                          {")"}
                                        </Text>
                                      </Button>
                                    ))}
                                  </Stack>
                                </Card>
                              </Tabs.Panel>

                              <Tabs.Panel value="dispositifsMedicaux">
                                <Card
                                  className="dispositifMedicalCard"
                                  shadow="lg"
                                  radius="lg"
                                  p="md"
                                  withBorder={true}
                                >
                                  <Stack
                                    className="DMList"
                                    align="stretch"
                                    justify="flex-start"
                                    gap="xs"
                                  >
                                    <Group justify="space-between">
                                      <Title size="h4">
                                        &nbsp;Ordonnance des Dispositifs
                                        Médicaux
                                      </Title>{" "}
                                      <GenerateOrdonnanceDispositifMedical type="ordonnance" />
                                    </Group>
                                    <SearchDispositifMedical className="SearchDispositifMedical" />
                                    {dispositifMedicalData?.map?.(
                                      (item, index) => (
                                        <Button
                                          key={item.id}
                                          className="medicamentsButton"
                                          justify="space-between"
                                          size="xs"
                                          variant="outline"
                                          radius="lg"
                                          color="#94ADA7"
                                          // onClick={() => {
                                          //   handleDispositifMedicalControlChange(item);
                                          // }}
                                          rightSection={
                                            <CloseButton
                                              className="CloseButton"
                                              zIndex="3000"
                                              onClick={() => {
                                                deleteDispositifMedical(
                                                  item.id
                                                );
                                              }}
                                              icon={
                                                <IconX
                                                  size={18}
                                                  stroke={1.5}
                                                  color="red"
                                                />
                                              }
                                            />
                                          }
                                          fullWidth
                                        >
                                          <Text
                                            className="TextDispositifMedical"
                                            size="xs"
                                            c="black"
                                            truncate="end"
                                            lineClamp={1}
                                          >
                                            {" "}
                                            <Group>
                                              <IconBandage
                                                className="MedicamentIcon"
                                                color="#94ADA7"
                                                style={{
                                                  verticalAlign: "middle",
                                                }}
                                              />

                                              {item.ServiceContent?.map?.(
                                                (service, index) => (
                                                  <>
                                                    <strong>
                                                      {
                                                        service?.label
                                                          ?.denominationCommerciale
                                                      }
                                                    </strong>
                                                    <Text>
                                                      {
                                                        service?.label
                                                          ?.denominationNotice
                                                      }
                                                    </Text>
                                                    {service?.label
                                                      ?.declarationArret ===
                                                    "oui" ? (
                                                      <Badge
                                                        variant="dot"
                                                        color="red"
                                                        size="xs"
                                                        radius="sm"
                                                      >
                                                        <Text
                                                          size="xs"
                                                          tt="capitalize"
                                                        >
                                                          arrêt
                                                        </Text>
                                                      </Badge>
                                                    ) : (
                                                      <Badge
                                                        variant="dot"
                                                        color="green"
                                                        size="xs"
                                                        radius="sm"
                                                      >
                                                        <Text
                                                          size="xs"
                                                          tt="capitalize"
                                                        >
                                                          disponible
                                                        </Text>
                                                      </Badge>
                                                    )}
                                                    <Switch
                                                      size="xs"
                                                      onLabel="ALD"
                                                      offLabel="ALD"
                                                      checked={service.tttAld}
                                                      onChange={(event) =>
                                                        handleDispositifMedicalSwitchChange(
                                                          item.ServiceId,
                                                          index,
                                                          event.currentTarget
                                                            .checked
                                                        )
                                                      }
                                                    />
                                                  </>
                                                )
                                              )}
                                            </Group>
                                          </Text>
                                        </Button>
                                      )
                                    )}
                                  </Stack>
                                </Card>
                              </Tabs.Panel>

                              <Tabs.Panel value="biologie">
                                <Card
                                  className="OrdoBioCard"
                                  shadow="lg"
                                  radius="lg"
                                  p="md"
                                  withBorder={true}
                                >
                                  <Stack
                                    className="biologiesList"
                                    align="stretch"
                                    justify="flex-start"
                                    gap="xs"
                                  >
                                    <Group justify="space-between">
                                      <Title size="h4">
                                        &nbsp;Ordonnance de Biologie
                                      </Title>
                                      <GenerateOrdonnanceBiologie type="ordonnance" />
                                    </Group>
                                    <SearchBiologies
                                      className="SearchBiologies"
                                      // handleBiologieClick={handleBiologieClick}
                                    />

                                    {biologieData?.map?.((item, index) => (
                                      <Button
                                        className="biologiesButton"
                                        justify="space-between"
                                        size="xs"
                                        variant="outline"
                                        radius="lg"
                                        color="#94ADA7"
                                        // leftSection={<IconShieldCheckered size={14} />}
                                        rightSection={
                                          <CloseButton
                                            onClick={() => {
                                              deleteBiologie(item.id);
                                            }}
                                            icon={
                                              <IconX
                                                size={18}
                                                stroke={1.5}
                                                color="red"
                                              />
                                            }
                                          />
                                        }
                                        fullWidth
                                      >
                                        <Text
                                          size="xs"
                                          color="black"
                                          truncate="end"
                                          lineClamp={1}
                                        >
                                          {" "}
                                          <Group>
                                            <IconDropletFilled
                                              className="MedicamentIcon"
                                              color="red"
                                              style={{
                                                verticalAlign: "middle",
                                                opacity: "0.6",
                                              }}
                                            />
                                            {item.ServiceContent?.map?.(
                                              (biologie, index) => (
                                                <>
                                                  <strong>
                                                    {biologie.label}
                                                  </strong>
                                                  <Switch
                                                    size="xs"
                                                    onLabel="ALD"
                                                    offLabel="ALD"
                                                    checked={biologie.tttAld}
                                                    onChange={(event) =>
                                                      handleBiologieSwitchChange(
                                                        item.ServiceId,
                                                        index,
                                                        event.currentTarget
                                                          .checked
                                                      )
                                                    }
                                                  />
                                                </>
                                              )
                                            )}
                                          </Group>
                                        </Text>
                                      </Button>
                                    ))}
                                  </Stack>
                                </Card>
                              </Tabs.Panel>

                              <Tabs.Panel value="radiologie">
                                <Card
                                  className="OrdoRadioCard"
                                  shadow="lg"
                                  radius="lg"
                                  p="md"
                                  withBorder={true}
                                >
                                  <Stack
                                    className="radiologiesList"
                                    align="stretch"
                                    justify="flex-start"
                                    gap="xs"
                                  >
                                    <Group justify="space-between">
                                      <Title size="h4">
                                        &nbsp;Ordonnance de Radiologie
                                      </Title>
                                      <GenerateOrdonnanceRadiologie type="ordonnance" />
                                    </Group>
                                    <SearchRadiologies />
                                    {radiologieData?.map?.((item, index) => (
                                      <Button
                                        className="radiolgieButton"
                                        justify="space-between"
                                        size="xs"
                                        variant="outline"
                                        radius="lg"
                                        color="#94ADA7"
                                        // leftSection={<IconShieldCheckered size={14} />}
                                        rightSection={
                                          <CloseButton
                                            onClick={() => {
                                              deleteRadiologie(item.id);
                                            }}
                                            icon={
                                              <IconX
                                                size={18}
                                                stroke={1.5}
                                                color="red"
                                              />
                                            }
                                          />
                                        }
                                        fullWidth
                                      >
                                        {" "}
                                        <Group>
                                          <IconRadioactiveFilled
                                            className="RadiolgieIcon"
                                            color="red"
                                            style={{
                                              verticalAlign: "middle",
                                              opacity: "0.6",
                                            }}
                                          />
                                          {item.ServiceContent?.map(
                                            (service, index) =>
                                              service.radiologie && (
                                                <>
                                                  <Tooltip
                                                    key={index}
                                                    label={
                                                      service.radiologie
                                                        .instructions &&
                                                      service.radiologie
                                                        .instructions.length >
                                                        0 ? (
                                                        <Stack gap="xs">
                                                          <Text size="sm">
                                                            {service.radiologie.instructions.map(
                                                              (
                                                                instruction,
                                                                index
                                                              ) => (
                                                                <div
                                                                  key={index}
                                                                >
                                                                  {instruction}
                                                                </div>
                                                              )
                                                            )}
                                                          </Text>
                                                          <Text size="xs">
                                                            Source: Société
                                                            Française de
                                                            Radiologie et
                                                            d'Imagerie Médicale
                                                            ( Aide à la demande
                                                            d’examens de
                                                            radiologie et
                                                            imagerie médicale
                                                            (ADERIM))
                                                          </Text>
                                                        </Stack>
                                                      ) : (
                                                        <Text size="sm">
                                                          No instructions
                                                          available
                                                        </Text>
                                                      )
                                                    }
                                                    color="#94ADA7"
                                                    withArrow
                                                    multiline
                                                    w={"20%"}
                                                    position="right"
                                                    radius="md"
                                                    arrowOffset={25}
                                                    arrowSize={8}
                                                    transitionProps={{
                                                      transition: "pop",
                                                      duration: 500,
                                                    }}
                                                    openDelay={1000}
                                                  >
                                                    <Group>
                                                      <Text
                                                        fw={550}
                                                        size="xs"
                                                        c="black"
                                                      >
                                                        {
                                                          service.radiologie
                                                            .examen
                                                        }
                                                      </Text>
                                                      <Text
                                                        size="xs"
                                                        c="black"
                                                        truncate="end"
                                                        lineClamp={1}
                                                      >
                                                        {
                                                          service.radiologie
                                                            .maladie
                                                        }{" "}
                                                        {service.radiologie
                                                          .contexte
                                                          ? `(${service.radiologie.contexte})`
                                                          : ""}
                                                      </Text>
                                                      <Switch
                                                        size="xs"
                                                        onLabel="ALD"
                                                        offLabel="ALD"
                                                        checked={service.tttAld}
                                                        onChange={(event) =>
                                                          handleRadiologieSwitchChange(
                                                            item.ServiceId,
                                                            index,
                                                            event.currentTarget
                                                              .checked
                                                          )
                                                        }
                                                      />
                                                    </Group>
                                                  </Tooltip>
                                                </>
                                              )
                                          )}
                                        </Group>
                                      </Button>
                                    ))}
                                  </Stack>
                                </Card>
                              </Tabs.Panel>

                              <Tabs.Panel value="autres">
                                <Card
                                  className="OrdoLibreCard"
                                  shadow="lg"
                                  radius="lg"
                                  p="md"
                                  withBorder={true}
                                >
                                  {/* <Group justify="space-between"> */}
                                  {/* <Title size="h4">
                                    &nbsp;Ordonnance Libre
                                  </Title> */}
                                  <GenerateOrdonnanceLibre />

                                  {/* </Group> */}
                                  <Editor />
                                </Card>
                              </Tabs.Panel>
                            </ScrollArea>
                          </Tabs>
                        </Grid.Col>
                      )}
                    </Grid>
                  </>
                )}
              </>

              {agendaOpened && (
                <Card
                  className="AgendaCard"
                  shadow="lg"
                  radius="lg"
                  withBorder={true}
                >
                  <Button
                    onClick={() => {
                      setAgendaOpened(false);
                      setShowPatientCard(true);
                    }}
                    className="closeAgendaButton"
                    variant="subtle"
                    color="gray"
                  >
                    x
                  </Button>

                  <Calendar currentUser={currentUser} />
                </Card>
              )}

              {mailboxOpened && <Mailbox />}
            </Stack>
          </div>
        ) : (
          <>
            <Login />
            <Stack>
              <Text
                className="homeTitle"
                fw={500}
                c="#94ADA7"
                style={{
                  fontSize: 60,
                  fontFamily: "Palatino",
                  position: "absolute",
                }}
              >
                iAvicenne
              </Text>
              <Text
                className="homeTitle-2"
                fw={500}
                c="#94ADA7"
                style={{
                  fontSize: 18,
                  fontFamily: "Palatino",
                  position: "absolute",
                }}
              >
                Changement de Paradigme Médical
              </Text>
            </Stack>
          </>
        )}
      </div>
    </MantineProvider>
  );
}

export default App;
