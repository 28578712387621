import { useStore } from "../Store";
import {
  Button,
  Image,
  Text,
  Group,
  Loader,
  Card,
  Stack,
  Title,
  List,
  Progress,
  Grid,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import axios from "axios";
import {
  IconCheck,
  IconDropletFilled,
  IconRadioactiveFilled,
} from "@tabler/icons-react";
import { AutoSearchBioPresc } from "./AutoSearchBiologie";
import { AutoSearchRadioPresc } from "./AutoSearchRadiologie";

const client = axios.create({ baseURL: "https://www.iavicenne.fr/" });

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

export function ExamensComplementairesButton({ maladie }) {
  const setExamensComplementaires = useStore(
    (state) => state.setExamensComplementaires
  );
  const setCurrentNotification = useStore(
    (state) => state.setCurrentNotification
  );

  const handleClick = async () => {
    try {
      const notificationId = notifications.show({
        withBorder: true,
        loading: false,
        radius: "lg",
        color: "#94ADA7",
        title: (
          <Group gap="xs">
            <Image
              fit="contain"
              src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
              alt="iAvicenne Logo"
              width={25}
              height={25}
              style={{ margin: "0", display: "block" }}
            />
            <Text fw={700}>Examens Complementaires</Text>
            <Loader className="ChatLoader" color="#94ADA7" type="dots" />
          </Group>
        ),
        opacity: 0.7,
        autoClose: false,
        withCloseButton: true,
      });
      setCurrentNotification(notificationId);

      const response = await client.get(`api/examens/?maladie=${maladie}`);
      if (response.status === 200) {
        notifications.update({
          id: notificationId,
          withBorder: true,
          loading: false,
          radius: "lg",
          color: "#94ADA7",
          title: (
            <Group gap="xs">
              <Image
                fit="contain"
                src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
                alt="iAvicenne Logo"
                width={25}
                height={25}
                style={{ margin: "0", display: "block" }}
              />
              <Text fw={700}>Recherche Terminée</Text>
              <IconCheck
                style={{ width: "1.1rem", color: "#94ADA7", height: "1.1rem" }}
              />
            </Group>
          ),
          opacity: 0.7,
          autoClose: 1000,
          withCloseButton: true,
        });
        setExamensComplementaires({
          ...response.data,
          maladie: maladie // Remplacez par la valeur souhaitée
      });
      }
    } catch (error) {
      console.error("Error fetching examens complementaires:", error);
      notifications.show({
        title: "Erreur",
        message: "Impossible de récupérer les examens complémentaires",
        color: "red",
      });
    }
  };

  return (
    <Button
      onClick={handleClick}
      className="propExamenButton"
      variant="outline"
      size="xs"
      color="#94ADA7"
      radius="lg"
      style={{ boxShadow: "0 2px 5px #bebebe" }}
    >
      <IconDropletFilled color="#94ADA7" size={10} />
      <IconRadioactiveFilled color="#94ADA7" size={10} />
    </Button>
  );
}

export function ShowExamensComplementaires() {
  const examensComplementaires = useStore(
    (state) => state.examensComplementaires
  );

  // console.log(examensComplementaires.examens);

  if (!examensComplementaires.examens) return null;

  return (
    <Stack>
      <Card shadow="lg" radius="lg" padding={20}>
        <Title size={22} ta="left" >
          {examensComplementaires?.maladie}
        </Title>
      </Card>
      {examensComplementaires.examens.biologie &&
        examensComplementaires.examens.biologie.examens.length > 0 && (
          <Card shadow="lg" radius="lg" padding={20}>
            <Group justify="space-between">
              <Title size={22} ta="left" style={{ marginBottom: "20px" }}>
                Examens Biologiques
              </Title>
              <AutoSearchBioPresc />
            </Group>
            {examensComplementaires.examens.biologie.examens.map(
              (examen, index) => (
                <Grid key={index}>
                  <Grid.Col span={2}>
                    <Progress.Root size="xl" style={{ marginTop: "5px" }}>
                      <Progress.Section
                        value={examen.score}
                        color={examen.score > 60 ? "lime" : "orange"}
                      >
                        <Progress.Label>
                          <Text ta="center" size="xs">
                            {examen.score}%
                          </Text>
                        </Progress.Label>
                      </Progress.Section>
                    </Progress.Root>
                  </Grid.Col>
                  <Grid.Col
                    span={10}
                    ta="start"
                    style={{ marginBottom: "5px" }}
                  >
                    <strong>{examen.examen}</strong>: {examen.anomalie}
                  </Grid.Col>
                </Grid>
              )
            )}
          </Card>
        )}

      {examensComplementaires.examens.radiologie &&
        examensComplementaires.examens.radiologie.examens.length > 0 && (
         
            <Card shadow="lg" radius="lg" padding={20}>
              <Group justify="space-between">
                <Title size={22} ta="left" style={{ marginBottom: "20px" }}>
                  Examens Radiologiques
                </Title>
                <AutoSearchRadioPresc />
              </Group>
              {examensComplementaires.examens.radiologie.examens.map(
                (examen, index) => (
                  <Grid key={index}>
                    <Grid.Col span={2}>
                      <Progress.Root size="xl" style={{ marginTop: "5px" }}>
                        <Progress.Section
                          value={examen.score}
                          color={examen.score > 60 ? "lime" : "orange"}
                        >
                          <Progress.Label>
                            <Text ta="center" size="xs">
                              {examen.score}%
                            </Text>
                          </Progress.Label>
                        </Progress.Section>
                      </Progress.Root>
                    </Grid.Col>
                    <Grid.Col span={10} style={{ marginBottom: "5px" }}>
                      <List withPadding={true} ta="left" size="md">
                        <List.Item>
                          <strong>{examen.titre}</strong>
                        </List.Item>
                        <List
                          withPadding={true}
                          ta="left"
                          size="sm"
                          style={{ marginBottom: "10px" }}
                        >
                          <List.Item>Indication: {examen.indication}</List.Item>
                          <List.Item>Anomalie: {examen.anomalie}</List.Item>
                        </List>
                      </List>
                    </Grid.Col>
                  </Grid>
                )
              )}
            </Card>
          
        )}
    </Stack>
  );
}
