import { useStore } from "../Store";
import {
  Button,
  Image,
  Text,
  Group,
  Loader,
  Card,
  Stack,
  Title,
  List,
  Grid,
  RingProgress
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import axios from "axios";
import { IconCheck, IconPills } from "@tabler/icons-react";

const client = axios.create({ baseURL: "https://www.iavicenne.fr/" });


axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;



export function PropositionsTherapeutiquesButton({ maladie }) {
  const setPropositionsTherapeutiques = useStore(
    (state) => state.setPropositionsTherapeutiques
  );
  const setCurrentNotification = useStore(
    (state) => state.setCurrentNotification
  );

  const handleClick = async () => {
    try {

      const notificationId = notifications.show({
        withBorder: true,
        loading: false,
        radius: "lg",
        color: "#94ADA7",
        title: (
          <Group gap="xs">
            <Image
              fit="contain"
              src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
              alt="iAvicenne Logo"
              width={25}
              height={25}
              style={{ margin: "0", display: "block" }}
            />
            <Text fw={700}>Traitements</Text>
            <Loader className="ChatLoader" color="#94ADA7" type="dots" />
          </Group>
        ),
        opacity: 0.7,
        autoClose: false,
        withCloseButton: true,


      });
      setCurrentNotification(notificationId);

      const response = await client.get(`api/traitements/fetch/?maladie=${maladie}`);
      if (response.status === 200) {
        notifications.update({
          id: notificationId,
          withBorder: true,
          loading: false,
          radius: "lg",
          color: "#94ADA7",
          title: (
            <Group gap="xs">
              <Image
                fit="contain"
                src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
                alt="iAvicenne Logo"
                width={25}
                height={25}
                style={{ margin: "0", display: "block" }}
              />
              <Text fw={700}>Recherche Terminée</Text>
              <IconCheck
                style={{ width: "1.1rem", color: "#94ADA7", height: "1.1rem" }}
              />
            </Group>
          ),
          opacity: 0.7,
          autoClose: 1000,
          withCloseButton: true,


        });
        setPropositionsTherapeutiques({
          ...response.data,
          maladie: maladie // Remplacez par la valeur souhaitée
      });
        // console.log(response.data);
      }
    } catch (error) {
      console.error("Error fetching examens complementaires:", error);
      notifications.show({
        title: "Erreur",
        message: "Impossible de récupérer les examens complémentaires",
        color: "red",
      });
    }
  };

  return (
    <Button
      onClick={handleClick}
      className="propExamenButton"
      variant="outline"
      size="xs"
      color="#94ADA7"
      radius="lg"
      style={{ boxShadow: "0 2px 5px #bebebe" }} 
    >
      <IconPills size={16} color="#94ADA7" />
    </Button>
  );
}

export function ShowPropositionsTherapeutiques() {
  const propositionsTherapeutiques = useStore(
    (state) => state.propositionsTherapeutiques
  );
 
  // console.log(propositionsTherapeutiques);

  if (!propositionsTherapeutiques.traitements) return null;

  return (
    <Stack>
      <Card shadow="lg" radius="lg" padding={20}>
        <Title size={22}  ta="left" >
          {propositionsTherapeutiques?.maladie}
        </Title>
        </Card>
      {propositionsTherapeutiques.traitements.médicaments && (
        <>
          {/* <Title size={22}>Médicaments</Title> */}

          {Object.entries(
            propositionsTherapeutiques.traitements.médicaments
          ).map(([key, value], index) => (
            <Card shadow="lg" radius="lg" padding={20} withBorder={true}>
              <Title size={20} style={{ marginBottom: "5" }} ta="left" tt="capitalize">
                {key}
                <Text size="sm" style={{ paddingLeft: "15" }}>
                  {value.classe}
                </Text>
              </Title>
              <Grid>
                <Grid.Col span={2}>
                <>
                        <RingProgress
                          size={90}
                          thickness={7}
                          roundCaps
                          sections={[
                            {
                              value: value.score,
                              color: value.score > 50 ? "lime" : "orange",
                            },
                          ]}
                          label={
                            <Text fw={700} ta="center" size="sm">
                              {value.score}%
                            </Text>
                          }
                        />
                      </>
                </Grid.Col>
                <Grid.Col span={10}>
                  <List ta="left" listStyleType="square" size="sm">
                    <List.Item>
                      <Text size="sm">
                        <strong>posologie</strong>: {value.posologie}
                      </Text>
                    </List.Item>

                    <List.Item>
                      <Text size="sm">
                        <strong>durée</strong>: {value.durée}
                      </Text>
                    </List.Item>

                    <List.Item>
                      <Text size="sm">
                        {value.reference && (
                          <>
                            <strong>references</strong>:
                            <List listStyleType="circle" size="xs" >
                              {Object.entries(value.reference).map(
                                ([refKey, refValue], refIndex) => (
                                  <List.Item
                                    withPadding={true}
                                    key={refIndex}
                                    size="xs"
                                  >
                                    <Text size="xs" style={{maxWidth:"90%"}}>{refValue}</Text>
                                  </List.Item>
                                )
                              )}
                            </List>
                          </>
                        )}
                      </Text>
                    </List.Item>
                  </List>
                </Grid.Col>
              </Grid>
            </Card>
          ))}
        </>
      )}

      {propositionsTherapeutiques.traitements.autres && (
        <Card shadow="lg" radius="lg">
          <Title size={20} ta="left" style={{ marginBottom: "5" }}>
            Traitements Adjuvants
          </Title>
          {Object.entries(propositionsTherapeutiques.traitements.autres).map(
            ([key, value], index) => (
              <div key={index}>
                <List ta="left" withPadding={true}>
                  <List.Item>
                    <Text size="sm">{value}</Text>
                  </List.Item>
                </List>
              </div>
            )
          )}
        </Card>
      )}
    </Stack>
  );
}
