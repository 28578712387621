
import {
  Group,
  Text,
  rem,
  Stack,
  ScrollArea,
  Button,
  CloseButton,
  Tooltip,
  SegmentedControl,
  Progress,
  Card,
} from "@mantine/core";
import {
  IconUpload,
  IconX,
  IconFileTypePdf,
  IconDropletFilled,
  IconRadioactiveFilled,
  IconMailFilled,
  IconFileCertificate,
  IconAlertTriangle,
  IconHeartbeat,
  IconPills,
  IconMicroscope,
  IconFileDescription,
  IconArrowBack,
} from "@tabler/icons-react";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import axios from "axios";
import { useStore } from "../Store";
import "@mantine/dropzone/styles.css";
import { useState, useEffect } from "react";
import "@mantine/notifications/styles.css";
import { RenewScanMedication } from "./AddFromSCanOrdonnance";

const client = axios.create({ baseURL: "https://www.iavicenne.fr/" });
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

function DocumentList() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filteredDocuments, setFilteredDocuments] = useState(null);
  const currentSessionInfo = useStore((state) => state.currentSessionInfo);
  const documents = useStore((state) => state.documents);
  const setDocuments = useStore((state) => state.setDocuments);
  const showEncouterObjects = useStore((state) => state.showEncouterObjects);

  const data = [
    {
      value: "reset",
      label: (
        <Tooltip label="Réinitialiser" color="#94ADA7" withArrow position="top">
          <IconArrowBack
            size={18}
          />
        </Tooltip>
      ),
    },
    {
      value: "biologie",
      label: (
        <IconDropletFilled
          size={18}
          
        />
      ),
    },
    {
      value: "radiologie",
      label: (
        <IconRadioactiveFilled
          size={18}
          
        />
      ),
    },
    {
      value: "courrier",
      label: (
        <IconMailFilled size={18}  />
      ),
    },
    {
      value: "ordonnance",
      label: <IconPills size={18}  />,
    },
    {
      value: "autres",
      label: (
        <IconFileTypePdf size={18}  />
      ),
    },
    {
      value: "administratif",
      label: (
        <IconFileCertificate
          size={18}
          
        />
      ),
    },
    {
      value: "électrocardiogramme",
      label: (
        <IconHeartbeat size={18}  />
      ),
    },
    {
      value: "anatomopathologie",
      label: (
        <IconMicroscope size={18}  />
      ),
    },
    {
      value: "endoscopie",
      label: (
        <IconFileDescription
          size={18}
          
        />
      ),
    },
  ];

  const [active, setActive] = useState(data[0].value);

  const fetchDocuments = async () => {
    try {
      setLoading(true);
      const response = await client.get(
        `/api/documents/?practitioner=${currentSessionInfo.practitioner}&patient=${currentSessionInfo.subject}`
      );
      setDocuments(response.data);
      setLoading(false);
    } catch (err) {
      setError("Erreur lors de la récupération des documents");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [currentSessionInfo]);

  const deletePDF = async (documentId) => {
    const prevData = documents.filter((item) => item.id !== documentId);
    setDocuments(prevData);

    // Mise à jour de filteredDocuments si non null
    if (filteredDocuments) {
      const updatedFilteredDocuments = filteredDocuments.filter(
        (item) => item.id !== documentId
      );
      setFilteredDocuments(updatedFilteredDocuments);
    }

    try {
      const response = await client.get(
        `/api/documents/${documentId}/delete-document/?documentId=${documentId}`
      );
      if (response.status === 200) {
        console.log("Fichier supprimé");
        // Mettre à jour l'état local en filtrant le document supprimé
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du document:", error);
    }
  };

  if (loading) return <div>Chargement des documents...</div>;
  if (error) return <div>{error}</div>;

  const handleDocumentsControlChange = (value) => {
    if (value === "reset") {
      setFilteredDocuments(null);
      setActive("");
    } else {
      setActive(value);
      setFilteredDocuments(documents.filter((item) => item.type === value));
    }
  };

  return (
    <Stack>
      {/* <Grid.Col span={1}> */}
        <SegmentedControl
          className="DocumentControl"
          withItemsBorders={false}
          orientation="horizontal"
          data={data}
          value={active}
          onChange={handleDocumentsControlChange}
          color="#94ADA7"
          radius="md"
          size="xs"
          transitionDuration={500}
          transitionTimingFunction="linear"
          fullWidth
        />
      {/* </Grid.Col>
      <Grid.Col span={10}> */}
        <Card withBorder={true} radius="lg" shadow="lg" h={"55vh"}>
          <ScrollArea
            offsetScrollbars
            scrollHideDelay={100}
            style={
              {
                // border:
                //   "calc(0.0625rem* var(--mantine-scale)) solid var(--mantine-color-gray-3);",
                // borderRadius: "var(--mantine-radius-lg)",
                // backgroundColor: "fff",
              }
            }
          >
            {filteredDocuments ? (
              <Stack gap="xs" style={{ padding: 15 }}>
                {filteredDocuments
                  .sort((a, b) => new Date(b.date) - new Date(a.date))
                  .map((doc) => (
                    <Tooltip
                      label={
                        <>
                          {doc.metadata.document_info?.resume &&
                            !doc.metadata.document_info?.ordonnance && (
                              <Text size="sm">
                                {doc.metadata.document_info.resume}
                              </Text>
                            )}
                          {doc.metadata.document_info?.ordonnance && (
                            <Text size="sm">
                              {Object.entries(
                                doc.metadata.document_info.ordonnance
                              ).map(([medicament, details], index) => (
                                <div key={index}>
                                  {medicament}: {details.posologie}
                                </div>
                              ))}
                            </Text>
                          )}
                        </>
                      }
                      color="#94ADA7"
                      withArrow
                      multiline
                      w={"35%"}
                      position="top"
                      radius="md"
                      arrowOffset={25}
                      arrowSize={8}
                      transitionProps={{ transition: "pop", duration: 500 }}
                      openDelay={1000}
                    >
                      <Button
                        key={doc.id}
                        className="documentsButton"
                        justify="space-between"
                        size="xs"
                        variant="white"
                        radius="md"
                        color="#94ADA7"
                        style={{
                          cursor: "unset",
                          boxShadow: "var(--mantine-shadow-lg)",
                        }}
                        rightSection={
                          <CloseButton
                            onClick={() => deletePDF(doc.id)}
                            icon={<IconX size={18} stroke={1.5} color="red" />}
                          />
                        }
                      >
                        {" "}
                        <a
                          href={`/api/documents/${doc.id}/view-document/?documentId=${doc.id}`}
                          target="_blank"
                        >
                          <Group style={{ flexWrap: "unset" }}>
                            {doc.type === "autres" && <IconFileTypePdf />}
                            {doc.type === "biologie" && <IconDropletFilled />}
                            {doc.type === "radiologie" && (
                              <IconRadioactiveFilled />
                            )}
                            {doc.type === "courrier" && <IconMailFilled />}
                            {doc.type === "administratif" && (
                              <IconFileCertificate />
                            )}
                            {doc.type === "électrocardiogramme" && (
                              <IconHeartbeat />
                            )}
                            {doc.type === "ordonnance" && <IconPills />}
                            {doc.type === "anatomopathologie" && (
                              <IconMicroscope />
                            )}
                            {doc.type === "endoscopie" && (
                              <IconFileDescription />
                            )}

                            <Text fw={700} tt="capitalize" size="xs" c="black">
                              {new Date(doc.date).toLocaleDateString()}&nbsp;-
                              &nbsp;
                            </Text>
                            <Text fw={700} tt="capitalize" size="xs" c="black">
                              {doc.type}
                            </Text>
                            <Text tt="capitalize" size="xs" c="black">
                              {doc.title}
                            </Text>
                            {!doc.metadata.matched_patients ||
                              (doc.metadata.matched_patients?.id &&
                                currentSessionInfo.patient !==
                                  doc.metadata.matched_patients.id && (
                                  <IconAlertTriangle color="red" />
                                ))}
                          </Group>
                        </a>
                      </Button>
                    </Tooltip>
                  ))}
              </Stack>
            ) : (
              <Stack gap="xs" style={{ padding: 15 }}>
                {documents
                  .sort((a, b) => new Date(b.date) - new Date(a.date))
                  .map((doc) => (
                    <Button
                      key={doc.id}
                      className="documentsButton"
                      justify="space-between"
                      size="xs"
                      variant="white"
                      radius="md"
                      color="#94ADA7"
                      style={{
                        cursor: "unset",
                        boxShadow: "var(--mantine-shadow-lg)",
                      }}
                      rightSection={
                        <CloseButton
                          onClick={() => deletePDF(doc.id)}
                          icon={<IconX size={18} stroke={1.5} color="red" />}
                        />
                      }
                    >
                      {" "}
                      <Group>
                        <a
                          href={`/api/documents/${doc.id}/view-document/?documentId=${doc.id}`}
                          target="_blank"
                        >
                          <Group>
                            {doc.type === "autres" && <IconFileTypePdf />}
                            {doc.type === "biologie" && <IconDropletFilled />}
                            {doc.type === "radiologie" && (
                              <IconRadioactiveFilled />
                            )}
                            {doc.type === "courrier" && <IconMailFilled />}
                            {doc.type === "administratif" && (
                              <IconFileCertificate />
                            )}
                            {doc.type === "électrocardiogramme" && (
                              <IconHeartbeat />
                            )}
                            {doc.type === "ordonnance" && <IconPills />}
                            {doc.type === "anatomopathologie" && (
                              <IconMicroscope />
                            )}
                            {doc.type === "endoscopie" && (
                              <IconFileDescription />
                            )}

                            <Text fw={700} tt="capitalize" size="xs" c="black">
                              {new Date(doc.date).toLocaleDateString()}&nbsp;-
                              &nbsp;
                            </Text>
                            <Tooltip
                              label={
                                <>
                                  {doc.metadata.document_info?.resume &&
                                    !doc.metadata.document_info?.ordonnance && (
                                      <Text size="xs">
                                        {doc.metadata.document_info.resume}
                                      </Text>
                                    )}
                                  {doc.metadata.document_info?.ordonnance && (
                                    <Text size="xs">
                                      {Object.entries(
                                        doc.metadata.document_info.ordonnance
                                      ).map(([medicament, details], index) => (
                                        <div key={index}>
                                          {medicament}: {details.posologie}
                                        </div>
                                      ))}
                                    </Text>
                                  )}
                                </>
                              }
                              color="#94ADA7"
                              withArrow
                              multiline
                              w={"35%"}
                              position="top"
                              radius="md"
                              arrowOffset={25}
                              arrowSize={8}
                              transitionProps={{
                                transition: "pop",
                                duration: 500,
                              }}
                              openDelay={1000}
                            >
                              <Text
                                fw={700}
                                tt="capitalize"
                                size="xs"
                                c="black"
                              >
                                {doc?.type} {doc?.metadata?.matched_patients?.id}
                              </Text>
                            </Tooltip>
                            <Text
                              tt="capitalize"
                              size="xs"
                              c="black"
                              truncate="end"
                              w={200}
                            >
                              {doc.title}
                            </Text>

                            {doc?.metadata?.matched_patients?.length === 0 || !doc?.metadata?.matched_patients && (
                              <IconAlertTriangle size={12} color="red" />
                            )}
                          </Group>
                        </a>
                        {doc.type === "ordonnance" && showEncouterObjects && (
                          <Group justify="flex-end">
                            <RenewScanMedication documentId={doc.id} />
                          </Group>
                        )}
                      </Group>
                    </Button>
                  ))}
              </Stack>
            )}
          </ScrollArea>
        </Card>
      {/* </Grid.Col> */}
    </Stack>
  );
}
export function DownloadFile() {
  const [loading, setLoading] = useState(false);

 

  const {
    currentUser,
    currentSessionInfo,
    documents,
    setDocuments,
    filesToUpload,
    setFilesToUpload,
  } = useStore();

  
  const [overallProgress, setOverallProgress] = useState(0);
  const [currentStep, setCurrentStep] = useState("");
  const [filesLength, setFilesLength] = useState(0);
  let processedFiles = 0;

  const fetchDocuments = async () => {
    try {
      setLoading(true);
      const response = await client.get(
        `/api/documents/?practitioner=${currentSessionInfo.practitioner}&patient=${currentSessionInfo.subject}`
      );
      setDocuments(response.data);
      setLoading(false);
    } catch (err) {
      console.log("Erreur lors de la récupération des documents");
      setLoading(false);
    }
  };
 

  const handleDrop = async (files) => {
    try {
      setLoading(true);
      setFilesLength(files.length);
      const totalFiles = files.length;
      setCurrentStep("Téléchargement en cours...");
      setOverallProgress(0);

      const uploadedFiles = [];

      // Première étape : télécharger tous les fichiers
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("practitioner", currentSessionInfo.practitioner);
        formData.append("patient", currentSessionInfo.subject);
        formData.append("title", file.name);
        formData.append("type", "autres");
        formData.append("size", file.size);

        const response = await client.post("/api/documents/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.status === 201) {
          setCurrentStep(
            `Téléchargement du fichier N°${response.data.id} terminé`
          );
          uploadedFiles.push(response.data.id);
        }
        setOverallProgress(((i + 1) / totalFiles) * 50);
      }

      setCurrentStep("Téléchargement terminé. Analyse en cours...");

      // Deuxième étape : analyser les fichiers un par un
      for (let i = 0; i < uploadedFiles.length; i++) {
        const documentId = uploadedFiles[i];
        console.log(documentId);
        await analyseDocument(documentId);
        processedFiles++;
        setOverallProgress(50 + (processedFiles / totalFiles) * 50);
      }

      setCurrentStep("Terminé");
      setOverallProgress(100);
      fetchDocuments();
    } catch (error) {
      console.error("Error uploading or analyzing files", error);
    } finally {
      setLoading(false);
    }
  };

  const analyseDocument = async (documentId) => {
    try {
      const response = await client.get(
        `/api/documents/${documentId}/patient/?documentId=${documentId}`
      );
      if (response.status === 200) {
        setCurrentStep(`Analyse du fichier N°${documentId} terminée`);
      }
    } catch (err) {
      console.log(`Erreur lors de l'analyse du document ${documentId}`, err);
    }
  };

  return (
    <Stack>
      {loading && (
        <>
          <Text size="sm">{currentStep}</Text>
          <Progress
            color="#94ADA7"
            value={overallProgress}
            transitionDuration={500}
            animated
          />
        </>
      )}
      <Dropzone
        onDrop={(files) => {
          setLoading(true);
          handleDrop(files);
        }}
        onReject={(files) => console.log("rejected files", files)}
        maxSize={5 * 1024 ** 20}
        accept={[MIME_TYPES.pdf]}
        radius="lg"
      >
        <Group
          justify="center"
          gap="xl"
          mih={220}
          style={{ pointerEvents: "none" }}
        >
          <Dropzone.Accept>
            <IconUpload
              style={{ width: rem(52), height: rem(52), color: "#94ADA7" }}
              stroke={1.5}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX
              style={{
                width: rem(52),
                height: rem(52),
                color: "var(--mantine-color-red-6)",
              }}
              stroke={1.5}
            />
          </Dropzone.Reject>
          <Stack justify="center" align="center">
            <Dropzone.Idle>
              <IconFileTypePdf
                style={{
                  width: rem(52),
                  height: rem(52),
                  color: "var(--mantine-color-dimmed)",
                }}
                stroke={1.5}
              />
            </Dropzone.Idle>

            <Text size="sm" c="dimmed" inline>
              Déposez vos PDF ici ou Cliquez pour les sélectionner
            </Text>

            <Text size="sm" c="dimmed" inline>
              Maximum 20 fichiers par téléchargement. Maximum 5 Mo par fichier.
            </Text>
          </Stack>
        </Group>
      </Dropzone>
      {documents && <DocumentList />}
    </Stack>
  );
}
